import React from "react";

import { Modal as BootstrapModal } from "bootstrap";

interface ModalState {}

interface ModalProps {
  title: string;
  children: React.ReactNode;
  shown: boolean;
  onDismissed: () => void;
}

class Modal extends React.Component<ModalProps, ModalState> {
  public bootstrapModal: BootstrapModal | null = null;
  public bootstrapModalRef: HTMLDivElement | null = null;

  componentDidMount() {
    if (this.bootstrapModalRef) {
      this.bootstrapModal = new BootstrapModal(this.bootstrapModalRef, {
        backdrop: "static",
        keyboard: false,
      });

      this.bootstrapModalRef.addEventListener(
        "hidePrevented.bs.modal",
        (event) => {
          this.props.onDismissed();
        }
      );

      this.updateShown();
    }
  }

  componentWillUnmount() {
    if (this.bootstrapModal) {
      this.bootstrapModal.dispose();
    }
  }

  componentDidUpdate(prevProps: ModalProps) {
    if (this.bootstrapModal) {
      this.bootstrapModal.handleUpdate();

      if (this.props.shown !== prevProps.shown) {
        this.updateShown();
      }
    }
  }

  updateShown() {
    if (!this.bootstrapModal) {
      return;
    }

    if (this.props.shown) {
      this.bootstrapModal.show();
    } else {
      this.bootstrapModal.hide();
    }
  }

  render() {
    return (
      <div
        className="modal"
        tabIndex={-1}
        ref={(element) => (this.bootstrapModalRef = element)}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.title}</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={this.props.onDismissed}
              ></button>
            </div>
            <div className="modal-body">{this.props.children}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={this.props.onDismissed}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
