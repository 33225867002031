import moment from "moment";
import React from "react";

import axios from "axios";
import Chart from "react-google-charts";

interface AppRangeDataDatum {
  date: string;
  backend_carrier: string;
  frontend_carrier: string;
  count: number;
}
interface AppRangeDataResponse {
  data: AppRangeDataDatum[];
  updated_at?: string;
}

interface CarrierSelection {
  [carrierKey: string]: true;
}

interface DashboardState {
  isLoading: boolean;
  data: AppRangeDataResponse;
  dateStart: moment.Moment;
  dateEnd: moment.Moment;

  view: "frontend" | "backend";
  carriersSelectedFront: CarrierSelection;
  carriersSelectedBack: CarrierSelection;

  modalShown: boolean;
}

interface DashboardProps {}

const dateFormat = "YYYY-MM-DD";

class Dashboard extends React.Component<DashboardProps, DashboardState> {
  public state: DashboardState;

  private refreshTimer: NodeJS.Timer | undefined;

  constructor(props: DashboardProps) {
    super(props);

    this.state = {
      isLoading: false,
      data: {
        data: [],
      },
      dateStart: moment().subtract(1, "days").subtract(3, "weeks"),
      dateEnd: moment().subtract(1, "days"),
      view: "frontend",
      carriersSelectedFront: {},
      carriersSelectedBack: {},
      modalShown: false,
    };
  }

  componentDidMount() {
    this.fetchData();
    this.refreshTimer = setInterval(() => this.fetchData(), 1000 * 60);
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimer);
  }

  componentDidUpdate(prevProps: DashboardProps, prevState: DashboardState) {
    if (
      !prevState.dateStart.isSame(this.state.dateStart) ||
      !prevState.dateEnd.isSame(this.state.dateEnd)
    ) {
      this.fetchData();
    }
  }

  async fetchData() {
    if (!this.state.dateStart.isValid() || !this.state.dateEnd.isValid()) {
      return;
    }

    this.setState({ isLoading: true });
    const resp = await axios.get<AppRangeDataResponse>(
      process.env.REACT_APP_API_BASE_URL +
        `/report/rangedata?dateStart=${encodeURIComponent(
          this.state.dateStart.format(dateFormat)
        )}&dateEnd=${encodeURIComponent(this.state.dateEnd.format(dateFormat))}`
    );

    this.setState({
      data: resp.data,
      isLoading: false,
    });
  }

  getCarriers(): string[] {
    const carriers: { [carrier: string]: true } = {};

    for (let i = 0; i < this.state.data.data.length; i++) {
      const datum = this.state.data.data[i];
      if (this.state.view === "frontend") {
        carriers[datum.frontend_carrier] = true;
      } else if (this.state.view === "backend") {
        carriers[datum.backend_carrier] = true;
      }
    }

    return Object.keys(carriers).sort();
  }

  getCarrierSelection(view: string, carrier: string): boolean {
    const selection =
      view === "frontend"
        ? this.state.carriersSelectedFront
        : this.state.carriersSelectedBack;
    return selection[carrier] ? true : false;
  }

  setCarrierSelection(view: string, carrier: string, checked: boolean) {
    const selection =
      view === "frontend"
        ? this.state.carriersSelectedFront
        : this.state.carriersSelectedBack;
    if (checked) {
      selection[carrier] = true;
    } else {
      delete selection[carrier];
    }

    if (view === "frontend") {
      this.setState({ carriersSelectedFront: selection });
    } else {
      this.setState({ carriersSelectedBack: selection });
    }
  }

  render() {
    // First convert report into date-accessible object
    const report = this.state.data.data;
    const reportByDate: { [date: string]: { [carrier: string]: number } } = {};
    for (var i = 0; i < report.length; i++) {
      const date = report[i].date;
      const carrier =
        this.state.view === "frontend"
          ? report[i].frontend_carrier
          : report[i].backend_carrier;
      const count = report[i].count;

      if (!reportByDate[date]) {
        reportByDate[date] = {};
      }
      if (!reportByDate[date][carrier]) {
        reportByDate[date][carrier] = 0;
      }

      reportByDate[date][carrier] += count;
    }

    // Create rows for use by Google's DataTable
    const rowsAllCarriers: any[] = [
      [
        { type: "date", label: "Date" },
        { type: "number", label: "All Carriers" },
      ],
    ];
    const rows = [];
    const rowsProportion = [];
    const totalAllDays: number[] = [];
    const carriersSelected = Object.keys(
      this.state.view === "frontend"
        ? this.state.carriersSelectedFront
        : this.state.carriersSelectedBack
    );

    for (const dateString in reportByDate) {
      // Parse date in local timezone instead of Date constructor parsing
      const dateArray = dateString.split("-");
      const date = new Date(
        parseInt(dateArray[0], 10),
        parseInt(dateArray[1], 10) - 1,
        parseInt(dateArray[2], 10)
      );
      const row: any[] = [date];
      const rowProportion: any[] = [date];
      let total = 0;
      for (var carrier in reportByDate[dateString]) {
        total += reportByDate[dateString][carrier];
      }
      rowsAllCarriers.push([date, total]);
      for (
        let carrierIndex = 0;
        carrierIndex < carriersSelected.length;
        carrierIndex++
      ) {
        const rowIndex = carrierIndex + 1;
        const carrier = carriersSelected[carrierIndex];
        row[rowIndex] = reportByDate[dateString][carrier] || 0;
        rowProportion[rowIndex] = (row[rowIndex] / total) * 100;
        totalAllDays[carrierIndex] = totalAllDays[carrierIndex]
          ? totalAllDays[carrierIndex] + row[rowIndex]
          : row[rowIndex];
      }
      rows.push(row);
      rowsProportion.push(rowProportion);
    }

    const columns = [{ type: "date", label: "Date" }];
    for (
      let carrierIndex = 0;
      carrierIndex < carriersSelected.length;
      carrierIndex++
    ) {
      const total = totalAllDays[carrierIndex];
      const name = carriersSelected[carrierIndex];
      columns.push({
        type: "number",
        label: `${name} (${total} total)`,
      });
    }

    rows.unshift(columns);
    rowsProportion.unshift(columns);

    return (
      <main>
        <div className="input-group mb-5">
          <span className="input-group-text">Date Range</span>
          <input
            type="date"
            className="form-control"
            value={this.state.dateStart.format(dateFormat)}
            onChange={(event) =>
              this.setState({
                dateStart: moment(event.target.value, dateFormat, true),
              })
            }
          />
          <input
            type="date"
            className="form-control"
            value={this.state.dateEnd.format(dateFormat)}
            onChange={(event) =>
              this.setState({
                dateEnd: moment(event.target.value, dateFormat, true),
              })
            }
          />
        </div>

        <h2 className="lead text-primary">
          {this.state.dateStart.format("dddd, MMMM D")} to{" "}
          {this.state.dateEnd.format("dddd, MMMM D, YYYY")}
          <div
            className={`spinner-border spinner-border-sm text-primary mx-2 ${
              this.state.isLoading ? "" : "visually-hidden"
            }`}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </h2>

        <div className="row">
          <div className="col">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={this.state.view === "backend"}
                role="switch"
                id="switchBackendCarrierName"
                onChange={(event) =>
                  this.setState({
                    view: event.target.checked ? "backend" : "frontend",
                  })
                }
              />
              <label
                className="form-check-label"
                htmlFor="switchBackendCarrierName"
              >
                Group by backend carrier name
              </label>
            </div>
            <hr />
            <div>
              {this.getCarriers().map((carrier) => {
                const key = `checkbox_${this.state.view}_${carrier
                  .toLowerCase()
                  .replace(/[^a-z]/g, "")}`;
                return (
                  <div className="form-check" key={key}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      checked={this.getCarrierSelection(
                        this.state.view,
                        carrier
                      )}
                      onChange={(event) =>
                        this.setCarrierSelection(
                          this.state.view,
                          carrier,
                          event.target.checked
                        )
                      }
                      id={key}
                    />
                    <label className="form-check-label" htmlFor={key}>
                      {carrier}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-9">
            {rows[0].length > 1 && (
              <>
                <Chart
                  options={{
                    title: "Number of Orders by Carrier",
                    vAxis: {
                      minValue: 0,
                      format: "0",
                    },
                  }}
                  chartType="LineChart"
                  data={rows}
                  width="100%"
                  height="400px"
                />
                <Chart
                  chartType="AreaChart"
                  options={{
                    title: "Share of Orders by Carrier",
                    isStacked: true,
                    vAxis: {
                      minValue: 0,
                      format: "#'%'",
                    },
                  }}
                  data={rowsProportion}
                  width="100%"
                  height="400px"
                />
              </>
            )}
            <Chart
              options={{
                title: "Number of Orders for All Carriers",
                vAxis: {
                  minValue: 0,
                  format: "0",
                },
              }}
              chartType="LineChart"
              data={rowsAllCarriers}
              width="100%"
              height="400px"
            />
          </div>
        </div>
      </main>
    );
  }
}

export default Dashboard;
