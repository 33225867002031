import jwtDecode from "jwt-decode";
import React from "react";

const clientID =
  "600510007356-cess7e0tdee17pi4de7q7fev9re9c839.apps.googleusercontent.com";

interface LoginButtonProps {
  credential: string | null;
  onChange: (credential: string | null) => void;
}
interface LoginButtonState {}

export default class LoginButton extends React.Component<
  LoginButtonProps,
  LoginButtonState
> {
  private loginButton: React.RefObject<HTMLDivElement> = React.createRef();

  private constructor(props: LoginButtonProps) {
    super(props);

    if (!window.google) {
      console.error(
        "The Google Sign In script has not yet been loaded during initialization."
      );
    } else {
      window.google.accounts.id.initialize({
        auto_select: true,
        context: "signin",
        client_id: clientID,
        cancel_on_tap_outside: false,
        callback: this.handleCredentialResponse.bind(this),
      });
    }
  }

  componentDidMount() {
    if (!window.google) {
      return;
    }

    if (this.props.credential === null) {
      window.google.accounts.id.prompt();
    }

    this.renderButton();
  }

  componentDidUpdate(prevProps: LoginButtonProps) {
    if (this.props.credential !== prevProps.credential) {
      if (this.props.credential === null) {
        this.renderButton();
      }
    }
  }

  componentWillUnmount() {
    window.google?.accounts.id.cancel();
  }

  renderButton() {
    if (!window.google || !this.loginButton.current) {
      return;
    }

    window.google.accounts.id.renderButton(this.loginButton.current, {
      type: "standard",
      theme: "outline",
      size: "large",
      text: "signin_with",
      shape: "rectangular",
      logo_alignment: "left",
    });
  }

  public logout(): void {
    window.google?.accounts.id.disableAutoSelect();
    this.props.onChange(null);
  }

  private handleCredentialResponse(res: CredentialResponse) {
    if (res.credential) {
      this.props.onChange(res.credential);
    }
  }

  public render() {
    if (this.props.credential === null) {
      return (
        <div>
          <div ref={this.loginButton}></div>
        </div>
      );
    }

    const token = jwtDecode(this.props.credential) as {
      [prop: string]: string | undefined;
    };
    const name = token.name;
    return (
      <div>
        <span className="me-2">
          Hello
          {name ? (
            <>
              , <strong>{name}</strong>
            </>
          ) : (
            false
          )}
          !
        </span>
        <button
          className="btn btn-outline-secondary"
          onClick={() => this.logout()}
        >
          Logout
        </button>
      </div>
    );
  }
}
