import moment from "moment";
import React from "react";

import axios from "axios";

import Modal from "./Modal";

interface AppTicketDetailsDatum {
  id: number;
  orderid: number;
  order: string;
  date: string;
  text: string;
  carrier: string;
}

interface AppTicketDetailsResponse {
  data: AppTicketDetailsDatum[];
  updated_at?: string;
}

interface TicketsModalState {
  isLoading: boolean;
  data: AppTicketDetailsResponse;
}

interface TicketsModalProps {
  dateStart: moment.Moment;
  dateEnd: moment.Moment;
  carrier: string | null;
  shown: boolean;
  onDismissed: () => void;
}

const dateFormat = "YYYY-MM-DD";

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function formatCurrency(num: number): string {
  if (num === 0) {
    return "—";
  }
  return formatter.format(num);
}

class TicketsModal extends React.Component<
  TicketsModalProps,
  TicketsModalState
> {
  public state: TicketsModalState;

  constructor(props: TicketsModalProps) {
    super(props);

    this.state = {
      isLoading: false,
      data: {
        data: [],
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: TicketsModalProps) {
    if (
      !prevProps.dateStart.isSame(this.props.dateStart) ||
      !prevProps.dateEnd.isSame(this.props.dateEnd) ||
      prevProps.carrier !== this.props.carrier
    ) {
      this.fetchData();
    }
  }

  async fetchData() {
    const { carrier, dateStart, dateEnd } = this.props;

    this.setState({
      data: {
        data: [],
      },
    });
    if (typeof carrier !== "string") {
      return;
    }

    this.setState({ isLoading: true });
    const resp = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
        `/report/tickets/detail?dateStart=${encodeURIComponent(
          dateStart.format(dateFormat)
        )}&dateEnd=${encodeURIComponent(
          dateEnd.format(dateFormat)
        )}&carrier=${encodeURIComponent(carrier)}`
    );
    this.setState({ data: resp.data, isLoading: false });
  }

  render() {
    return (
      <Modal
        title={`Tickets for Orders ${
          this.props.carrier ? `for ${this.props.carrier}` : "Without Shipping"
        } between ${this.props.dateStart.format(
          "ddd, MMM Do, YYYY"
        )} and ${this.props.dateEnd.format("ddd, MMM Do")}`}
        shown={this.props.shown}
        onDismissed={this.props.onDismissed}
      >
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Order Date</th>
              <th scope="col">Order</th>
              <th scope="col">Text</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.data.map((row) => (
              <tr key={row.id}>
                <td>
                  <a
                    href={`https://eightvape.gorgias.com/app/ticket/${row.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>{moment(row.date).calendar()}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-box-arrow-up-right mx-2"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                      />
                    </svg>
                  </a>
                </td>
                <td>
                  <a
                    href={`https://eightcig.myshopify.com/admin/orders/${row.orderid}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>{row.order}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-box-arrow-up-right mx-2"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                      />
                    </svg>
                  </a>
                </td>
                <td className="text-end text-truncate">{row.text.trim()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    );
  }
}

export default TicketsModal;
