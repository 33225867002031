import axios from "axios";
import React from "react";
import { NavLink, Outlet } from "react-router-dom";

import GoogleSignin from "./GoogleSignin";
import LoginButton from "./LoginButton";

interface AppState {
  credential: string | null;
}

interface AppProps {
  children?: React.ReactNode;
}

const localStorageCredentialKey = "credential";

export default class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      credential: window.localStorage.getItem(localStorageCredentialKey),
    };

    // Register credential for all HTTP requests
    axios.interceptors.request.use((config) => {
      const credential = this.state.credential;
      if (credential !== null) {
        if (!config.headers) {
          config.headers = {};
        }
        config.headers["Authorization"] = `Bearer ${credential}`;
      }
      return config;
    });

    // Remove credential that does not work
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 401) {
            this.setState({ credential: null });
          }
        }
        return error;
      }
    );
  }

  componentDidUpdate(prevProps: AppProps, prevState: AppState) {
    if (prevState.credential !== this.state.credential) {
      if (this.state.credential) {
        window.localStorage.setItem(
          localStorageCredentialKey,
          this.state.credential
        );
      } else {
        window.localStorage.removeItem(localStorageCredentialKey);
      }
    }
  }

  public render() {
    return (
      <div className="container">
        <div className="my-4">
          <div className="float-end">
            <LoginButton
              credential={this.state.credential}
              onChange={(credential) => this.setState({ credential })}
            />
          </div>
          <h1 className="display-5">
            Daily Sales&nbsp;
            <span className="lead d-none d-md-inline">
              Breakdown by carrier
            </span>
          </h1>
        </div>

        {!this.state.credential ? (
          <div className="alert alert-primary">
            You are not signed in. Please sign in to continue.
          </div>
        ) : (
          <div className="pb-5">
            <ul className="nav nav-pills mb-3">
              <li className="nav-item">
                <NavLink to="/" className="nav-link" aria-current="page">
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/tickets" className="nav-link" aria-current="page">
                  Tickets
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/day" className="nav-link" aria-current="page">
                  Day Report
                </NavLink>
              </li>
            </ul>

            <Outlet />
          </div>
        )}
      </div>
    );
  }
}
