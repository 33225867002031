import moment from "moment";
import React from "react";

import axios from "axios";
import Chart from "react-google-charts";
import TicketsModal from "./TicketsModal";

interface AppTicketDataDatum {
  tickets: number;
  orders: number;
  rate: number;
  backend_carrier: string;
}
interface AppTicketDataResponse {
  data: AppTicketDataDatum[];
  updated_at?: string;
}

interface DashboardTicketsState {
  isLoading: boolean;
  data: AppTicketDataResponse;
  dateStart: moment.Moment;
  dateEnd: moment.Moment;

  modalShown: boolean;
  carrierSelected: string | null;
}

interface DashboardTicketsProps {}

const dateFormat = "YYYY-MM-DD";

class DashboardTickets extends React.Component<
  DashboardTicketsProps,
  DashboardTicketsState
> {
  public state: DashboardTicketsState;

  private refreshTimer: NodeJS.Timer | undefined;

  constructor(props: DashboardTicketsProps) {
    super(props);

    this.state = {
      isLoading: false,
      data: {
        data: [],
      },
      dateStart: moment().subtract(1, "days").subtract(3, "weeks"),
      dateEnd: moment().subtract(1, "days"),
      modalShown: false,
      carrierSelected: null,
    };
  }

  componentDidMount() {
    this.fetchData();
    this.refreshTimer = setInterval(() => this.fetchData(), 1000 * 60);
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimer);
  }

  componentDidUpdate(
    prevProps: DashboardTicketsProps,
    prevState: DashboardTicketsState
  ) {
    if (
      !prevState.dateStart.isSame(this.state.dateStart) ||
      !prevState.dateEnd.isSame(this.state.dateEnd)
    ) {
      this.fetchData();
    }
  }

  async fetchData() {
    if (!this.state.dateStart.isValid() || !this.state.dateEnd.isValid()) {
      return;
    }

    this.setState({ isLoading: true });
    const resp = await axios.get<AppTicketDataResponse>(
      process.env.REACT_APP_API_BASE_URL +
        `/report/tickets?dateStart=${encodeURIComponent(
          this.state.dateStart.format(dateFormat)
        )}&dateEnd=${encodeURIComponent(this.state.dateEnd.format(dateFormat))}`
    );

    this.setState({
      data: resp.data,
      isLoading: false,
    });
  }

  render() {
    const ticketsSum = this.state.data.data.reduce(
      (prev, datum) => prev + datum.tickets,
      0
    );
    const ordersSum = this.state.data.data.reduce(
      (prev, datum) => prev + datum.orders,
      0
    );
    return (
      <main>
        <div className="input-group mb-5">
          <span className="input-group-text">Date Range</span>
          <input
            type="date"
            className="form-control"
            value={this.state.dateStart.format(dateFormat)}
            onChange={(event) =>
              this.setState({
                dateStart: moment(event.target.value, dateFormat, true),
              })
            }
          />
          <input
            type="date"
            className="form-control"
            value={this.state.dateEnd.format(dateFormat)}
            onChange={(event) =>
              this.setState({
                dateEnd: moment(event.target.value, dateFormat, true),
              })
            }
          />
        </div>

        <h2 className="lead text-primary">
          All Tickets from Orders Created{" "}
          {this.state.dateStart.format("dddd, MMMM D")} to{" "}
          {this.state.dateEnd.format("dddd, MMMM D, YYYY")}
          <div
            className={`spinner-border spinner-border-sm text-primary mx-2 ${
              this.state.isLoading ? "" : "visually-hidden"
            }`}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </h2>

        <div className="card border-primary text-primary text-center mb-3">
          <div className="card-body d-flex">
            <div className="flex-fill">
              <h6>Total Rate</h6>
              <span className="display-6">
                {((ticketsSum / ordersSum) * 100).toFixed(2)}%
              </span>
            </div>
            <div className="flex-fill">
              <h6>Total Tickets</h6>
              <span className="display-6">{ticketsSum}</span>
            </div>
            <div className="flex-fill">
              <h6>Total Orders</h6>
              <span className="display-6">{ordersSum}</span>
            </div>
          </div>
        </div>

        <TicketsModal
          dateStart={this.state.dateStart}
          dateEnd={this.state.dateEnd}
          carrier={this.state.carrierSelected}
          shown={this.state.modalShown}
          onDismissed={() => this.setState({ modalShown: false })}
        />

        <div>
          <table className="table table-clickable">
            <thead>
              <tr>
                <th>Rate</th>
                <th>Tickets</th>
                <th>Orders</th>
                <th>Carrier</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.data.map((datum) => (
                <tr
                  key={datum.backend_carrier}
                  onClick={() =>
                    this.setState({
                      modalShown: true,
                      carrierSelected: datum.backend_carrier,
                    })
                  }
                >
                  <td>{datum.rate.toFixed(2)}%</td>
                  <td>{datum.tickets}</td>
                  <td>{datum.orders}</td>
                  <td>{datum.backend_carrier}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    );
  }
}

export default DashboardTickets;
