import moment from "moment";
import React from "react";

import axios from "axios";

import OrdersModal from "./OrdersModal";

interface AppDailySalesDatum {
  backend_carrier: string;
  frontend_carrier: string;
  count: number;
  total_price: string;
  average_price: string;
  mean_shipping_price: string;
}

interface AppDailySalesResponse {
  total: number;
  total_sales: number;
  data: AppDailySalesDatum[];
  updated_at?: string;
}

interface DashboardTableRow {
  carrier: string;
  count: number;
  total_price: number;
  average_price: number;
  mean_shipping_price: number;
}

interface DashboardState {
  isLoading: boolean;
  data: AppDailySalesResponse;
  dateStart: moment.Moment;
  dateEnd: moment.Moment;

  view: "frontend" | "backend";
  carrierSelected?: string;

  modalShown: boolean;
}

interface DashboardProps {}

const dateFormat = "YYYY-MM-DD";

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function formatCurrency(num: number): string {
  return formatter.format(num);
}

function weightedAverage(a: number, b: number, m: number, n: number): number {
  return (a * m + b * n) / (m + n);
}

class DashboardDay extends React.Component<DashboardProps, DashboardState> {
  public state: DashboardState;

  private refreshTimer: NodeJS.Timer | undefined;

  constructor(props: DashboardProps) {
    super(props);

    this.state = {
      isLoading: false,
      data: {
        total: 0,
        total_sales: 0,
        data: [],
      },
      dateStart: moment(),
      dateEnd: moment(),
      view: "frontend",
      modalShown: false,
    };
  }

  componentDidMount() {
    this.fetchData();
    this.refreshTimer = setInterval(() => this.fetchData(), 1000 * 60);
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimer);
  }

  componentDidUpdate(prevProps: DashboardProps, prevState: DashboardState) {
    if (
      !prevState.dateStart.isSame(this.state.dateStart) ||
      !prevState.dateEnd.isSame(this.state.dateEnd)
    ) {
      this.fetchData();
    }
  }

  async fetchData() {
    if (!this.state.dateStart.isValid() || !this.state.dateEnd.isValid()) {
      return;
    }

    this.setState({ isLoading: true });
    const resp = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
        `/report/dailysales?dateStart=${encodeURIComponent(
          this.state.dateStart.format(dateFormat)
        )}&dateEnd=${encodeURIComponent(this.state.dateEnd.format(dateFormat))}`
    );
    this.setState({ data: resp.data, isLoading: false });
  }

  createView(
    data: AppDailySalesResponse,
    view: "frontend" | "backend"
  ): DashboardTableRow[] {
    const key = view === "frontend" ? "frontend_carrier" : "backend_carrier";
    const carriers: { [key: string]: DashboardTableRow } = {};

    for (let i = 0; i < data.data.length; i++) {
      const name = data.data[i][key];

      const row = carriers[name];

      if (!row) {
        carriers[name] = {
          carrier: name,
          count: data.data[i].count,
          total_price: parseFloat(data.data[i].total_price),
          average_price: parseFloat(data.data[i].average_price),
          mean_shipping_price: parseFloat(data.data[i].mean_shipping_price),
        };
      } else {
        const m = row.count;
        const n = data.data[i].count;

        row.count += data.data[i].count;
        row.total_price =
          row.total_price + parseFloat(data.data[i].total_price);
        row.average_price = weightedAverage(
          row.average_price,
          parseFloat(data.data[i].average_price),
          m,
          n
        );
        row.mean_shipping_price = weightedAverage(
          row.mean_shipping_price,
          parseFloat(data.data[i].mean_shipping_price),
          m,
          n
        );
      }
    }

    return Object.keys(carriers)
      .map((name) => carriers[name])
      .sort((a, b) => {
        return b.count - a.count;
      });
  }

  render() {
    return (
      <main>
        <div className="input-group mb-5">
          <span className="input-group-text">Date Range</span>
          <input
            type="date"
            className="form-control"
            value={this.state.dateStart.format(dateFormat)}
            onChange={(event) =>
              this.setState({
                dateStart: moment(event.target.value, dateFormat, true),
              })
            }
          />
          <input
            type="date"
            className="form-control"
            value={this.state.dateEnd.format(dateFormat)}
            onChange={(event) =>
              this.setState({
                dateEnd: moment(event.target.value, dateFormat, true),
              })
            }
          />
        </div>

        <h2 className="lead text-primary">
          {this.state.dateStart.format("dddd, MMMM D")} to{" "}
          {this.state.dateEnd.format("dddd, MMMM D, YYYY")}
          <div
            className={`spinner-border spinner-border-sm text-primary mx-2 ${
              this.state.isLoading ? "" : "visually-hidden"
            }`}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </h2>

        <div className="card border-primary text-primary text-center mb-3">
          <div className="card-body d-flex">
            <div className="flex-fill">
              <h6>Total Orders</h6>
              <span className="display-6">{this.state.data.total}</span>
            </div>
            <div className="flex-fill">
              <h6>Average Sale</h6>
              <span className="display-6">
                {this.state.data.total > 0
                  ? formatCurrency(
                      this.state.data.total_sales / this.state.data.total
                    )
                  : "—"}
              </span>
            </div>
            <div className="flex-fill">
              <h6>Total Sales</h6>
              <span className="display-6">
                {formatCurrency(this.state.data.total_sales)}
              </span>
            </div>
          </div>
        </div>

        <OrdersModal
          date={this.state.dateStart}
          view={this.state.view}
          carrier={this.state.carrierSelected}
          shown={this.state.modalShown}
          onDismissed={() => this.setState({ modalShown: false })}
        />

        <div className="card my-3">
          <div className="card-header">
            <div className="form-check form-switch float-end">
              <input
                className="form-check-input"
                type="checkbox"
                checked={this.state.view === "backend"}
                role="switch"
                id="switchBackendCarrierName"
                onChange={(event) =>
                  this.setState({
                    view: event.target.checked ? "backend" : "frontend",
                  })
                }
              />
              <label
                className="form-check-label"
                htmlFor="switchBackendCarrierName"
              >
                Group by backend carrier name
              </label>
            </div>
          </div>
          <div className="card-body">
            <div className="card-group">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Shopify</h5>
                  <table className="table table-clickable">
                    <thead>
                      <tr>
                        <th scope="col">Carrier</th>
                        <th scope="col">Orders</th>
                        <th scope="col" className="text-end">
                          Average Shipping Charge
                        </th>
                        <th scope="col" className="text-end">
                          Average Sale
                        </th>
                        <th scope="col" className="text-end">
                          Total Sales
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.createView(this.state.data, this.state.view).map(
                        (row, index) => (
                          <tr
                            key={index}
                            onClick={() =>
                              this.setState({
                                modalShown: true,
                                carrierSelected: row.carrier,
                              })
                            }
                          >
                            <td>{row.carrier}</td>
                            <td>{row.count}</td>
                            <td className="text-end">
                              {formatCurrency(row.mean_shipping_price)}
                            </td>
                            <td className="text-end">
                              {formatCurrency(row.average_price)}
                            </td>
                            <td className="text-end">
                              {formatCurrency(row.total_price)}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="card-footer">
                  <small className="text-muted">
                    Last updated{" "}
                    {this.state.data.updated_at
                      ? moment(this.state.data.updated_at).fromNow()
                      : "never"}
                  </small>
                </div>
              </div>
              {/* <div className="card">
              <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">
                  This card has supporting text below as a natural lead-in to
                  additional content.
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted">Last updated 3 mins ago</small>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">
                  This is a wider card with supporting text below as a natural
                  lead-in to additional content. This card has even longer
                  content than the first to show that equal height action.
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted">Last updated 3 mins ago</small>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default DashboardDay;
